import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VerticalImage from "../components/vertical-image"
import VerticalText from "../components/vertical-text"
import styled from '@emotion/styled'
import { graphql } from "gatsby"
import { mediaQuery } from "../components/shared-styles"

import iconFacebook from '../images/facebook.svg';
import iconInstagram from '../images/instagram.svg';

const StyledVerticalImage = styled(VerticalImage)({
  display: 'none',
  [mediaQuery.medium]: ({
    display: 'block',
  })
})

const Text = styled('div')({
  textAlign: 'left',
  paddingLeft: '50px',
  paddingTop: '50px',
});

const SocialBlock = styled('div')({
  padding: '20px 0',
});

const SocialLink = styled('a')({
  display: 'inline-block',
  verticalAlign: 'top',
  padding: '10px 10px',
});

const FacebookIcon = styled('div')({
  backgroundImage: `url(${iconFacebook})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '45px',
  height: '45px',
  display: 'inline-block',
});

const InstagramIcon = styled('div')({
  backgroundImage: `url(${iconInstagram})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '45px',
  height: '45px',
  display: 'inline-block',
});

export const query = graphql`
  query {
    file(relativePath: { eq: "podkrovni-byt/byt-2.jpg" }) { ...fluidImage }
  }
`

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Kontakt" content="Ing. arch. Marek Svoboda architektura 3D realizace interiéry byty exteriéry" />
    <StyledVerticalImage borderRight marginTop image={data.file.childImageSharp.fluid} />
    <VerticalText>
      <Text>
        <ul>
          <li>Ing. arch. <strong>Marek Svoboda</strong></li>
          <li>Františka Křížka 362/1<br />170 00 Praha 7</li>
          <li>Telefon: <a href='tel:+420603858725'>603 858 725</a></li>
          <li>Email: <a href='mailto:info@svobodadesign.cz'>info@svobodadesign.cz</a></li>
        </ul>
        <SocialBlock>
          <div>
            <InstagramIcon />
            <SocialLink href='https://www.instagram.com/svobodnydesign/' target='_blank'>#SvobodnyDesign</SocialLink>
          </div>
          <div>
            <FacebookIcon />
            <SocialLink href='https://www.facebook.com/SvobodaDesign/' target='_blank'>@SvobodaDesign</SocialLink>
          </div>
        </SocialBlock>
      </Text>
    </VerticalText>
  </Layout>
)

export default ContactPage
